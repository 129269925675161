import React, { useState, useEffect, useRef } from 'react'
import { Icon } from '@iconify/react';

export default function HeaderStepper({ steps, currentStep }) {
   const stepRef = useRef();
   const [newSteps, setNewSteps] = useState([]);

   const updatedStep = (stepNumber, stepsArr) => {
      const newSteps = [...stepsArr];
      let count = 0;
      while (count < newSteps.length) {
         if (count === stepNumber) {
            newSteps[count] = {
               ...newSteps[count],
               selected: true,
            }
            count++;
         } else if (count < stepNumber) {
            newSteps[count] = {
               ...newSteps[count],
               selected: true,
               completed: true
            }
            count++;
         }
         else {
            newSteps[count] = {
               ...newSteps[count],
               selected: false,
               completed: false
            }
            count++;
         }
         if (count === stepNumber - 1) {
            newSteps[count] = {
               ...newSteps[count],
               completed: true,
            }
         }
      }
      return newSteps
   }

   useEffect(() => {
      const stepsState = steps.map((step, index) =>
         Object.assign(
            {},
            {
               description: step,
               completed: false,
               selected: index === 0 ? true : false
            }
         ));
      stepRef.current = stepsState;
      const current = updatedStep(currentStep - 1, stepRef.current);
      setNewSteps(current);
   }, [steps, currentStep]);

   return (
      <div className="flex justify-center text-white text-[12px] sm:text-[20px] font-semibold my-[2rem]">
         {
            newSteps.map((step, index) => {
               return (
                  <div className=''>
                     <div key={index} className='flex items-baseline justify-center'>
                        <div className='flex flex-col items-center gap-[0.5rem]'>
                           <span className={`flex items-center justify-center h-[50px] w-[50px] rounded-full mx-[3rem] ${step.completed ? "bg-primary" : "bg-[#D9D9D9]"}`}>
                              {step.completed ? <Icon icon="mdi:tick" /> : index + 1}
                           </span>

                           <span
                              className={`text-[#D9D9D9] ${step.selected && "text-primary"}`}>
                              {step.description}
                           </span>
                        </div>
                        <span className={`w-[100%] sm:max-md:w-[100px] md:w-[230px] h-[4px] bg-[#D9D9D9] ${newSteps.length === index + 1 ? "hidden" : ""} ${step.completed ? "bg-primary": ""}`}></span>
                     </div>
                  </div>
               )
            })
         }
      </div>
   )
}

