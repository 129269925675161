export let baseUrl = "http://localhost:4000";



// const type = "DEV";
const type = "PROD"

if (type === "PROD") {
    baseUrl = "https://api.strikexgaming.com"
}
else {
    baseUrl = "http://localhost:4000"
}


export const APIurls = {
    fetchLottary: `${baseUrl}/agents/fetch-agent-lottary`,
    fetchAgentDetails: `${baseUrl}/agents/fetch-agent-details`,
    createLottary: `${baseUrl}/agents/make-private-lottary`,
    fetchLottaryById: `${baseUrl}/agents/fetch-single-lottary`,
    updateLottary: `${baseUrl}/agents/edit-private-lottary-details`,
    announceWinner: `${baseUrl}/agents/announce-private-lottery-winner`,
    getRoles: `${baseUrl}/api/v2/get-roles`,
    fetchUsers: `${baseUrl}/api/v2/fetch-users`,
    fetchUserById: `${baseUrl}/api/v2/fetch-single-user`,
    fetchSingleLottaryView: `${baseUrl}/agents/fetch-single-lottary`,
    inviteUsers: `${baseUrl}/agents/invite-users-agent`,
    fetchFirebaseNotifications: `${baseUrl}/agents/fetch-firebase-notifications`,
}