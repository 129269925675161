import React, { useState } from 'react';
import { IconButton } from '@material-tailwind/react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Icon } from '@iconify/react';
// import './privateLotteryStyles.css';
import { APIurls } from "../../api/apiConstant";
import { useAuth } from "../../store/AuthContext";
import { toast } from 'react-toastify';

export default function UserModal({inviteId}) {

    const [email, setEmail] = useState("");
    const [displayEmailData, setDisplayEmailData] = useState([]);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isInviteBtnClicked, setIsInviteBtnClicked] = useState(false);
    const [showKeepInvite, setShowKeepInvite] = useState(false);
    const [emailData, setEmailData] = useState([]);
    const [inviteFailed, setInviteFailed] = useState(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const { getAccessToken } = useAuth();

    const inputChangeHandler = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        if (emailRegex.test(e.target.value)) {
            setIsEmailValid(true);
        }
    }

    const keyDownHandler = (e) => {
        if (e.key === 'Enter') {
            if (!(e.target.value)) {
                return;
            } else if (emailRegex.test(e.target.value)) {
                setDisplayEmailData((prevData) => [{ email: email, id: Math.random().toString() }, ...prevData]);
                setEmail("");
                setIsEmailValid(true);
            }
            else {
                setIsEmailValid(false)
            }
        }
        // console.log("KEY DOWN", e.target.value);
    }

    const deleteEmailHandler = (id) => {
        setDisplayEmailData((prevData) => prevData.filter((data) => data.id !== id))
    }

    const inviteHandler = async () => {
        console.log("inviteHandler Triggered");
        // Function to Post Display Email Data to the Backend
        setEmailData(displayEmailData.map((data) => data.email))
        const token = await getAccessToken()
        try {
            const response = await fetch(APIurls.inviteUsers, {
                method: 'POST',
                body: JSON.stringify({
                    "array_of_emails": displayEmailData,
                    "ticket_id": inviteId,
                }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            if (!response.ok) {
                setInviteFailed(true);
                throw new Error("Failed to Send Invite Email");
            }
            const result = await response.json();
            console.log("Invite API", result);
            toast.success("Invite Email Sent Successfully");
            setIsInviteBtnClicked(true);
        } catch (error) {
            console.warn("Failed to Send Invite Email")
            toast.error("Failed to Send Invite Email")
        }
        setDisplayEmailData([]);
        setEmailData([]);
    }
    
    const keepInviteHandler = () => {
        setIsInviteBtnClicked(false);
        setShowKeepInvite(false);
    }
    
    const closeHandler = (close) => {
        close();
        setInviteFailed(false);
        setIsInviteBtnClicked(false);
        setShowKeepInvite(false);
        console.log('closeHandler Triggered')
    }

    const closeWithoutInviteHandler = () => {
        setShowKeepInvite(true);
    }

    return (
        <Popup className=''
            trigger={
                <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                    <Icon
                        icon="mdi:user-add-outline"
                        className="text-[1.2rem] sm:text-[1.5rem]"
                    />
                    <span className="whitespace-nowrap">Invite Users</span>
                </button>}
            modal
            nested
        >
            {close => (
                <div className="">
                    {
                        !isInviteBtnClicked && !showKeepInvite && !inviteFailed && (
                            <div>
                                <div className="flex items-center justify-between p-[1rem]">
                                    <h1 className='text-[1.5rem] font-semibold'>
                                        Add Users
                                    </h1>
                                    <IconButton onClick={closeWithoutInviteHandler}
                                        className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
                                        size='sm'
                                        style={{
                                            borderRadius: "5px",
                                            border: "1px solid #FF0023",
                                        }}
                                    >
                                        <Icon
                                            icon="material-symbols:close"
                                            className="text-[1.5rem]"
                                        />
                                    </IconButton>
                                </div>
                                <div className="px-[1rem] flex flex-col gap-[1rem]">
                                    {!isEmailValid && (
                                        <p className="text-sm text-[#E92215]">
                                            *Invalid Email
                                        </p>
                                    )}
                                    <div className='border border-gray-500 rounded-md max-h-[20rem] overflow-auto'>
                                        <input
                                            className='w-full outline-none py-[0.5rem] px-[1rem] font-semibold rounded-md'
                                            type="email" name="" id=""
                                            value={email}
                                            onChange={inputChangeHandler}
                                            onKeyDown={keyDownHandler}
                                        />
                                        {displayEmailData.map((data, index) => {
                                            return (
                                                <p key={index} className='mx-[1rem] px-[1rem] flex items-center gap-[0.5rem] text-[#858585] bg-[#F0F4F9] mb-[0.5rem]'>
                                                    <Icon icon="streamline:send-email" className='text-[0.8rem]' />
                                                    <span>{data.email}</span>
                                                    <Icon icon="iconoir:cancel" className='text-[1.1rem] cursor-pointer'
                                                        onClick={() => deleteEmailHandler(data.id)}
                                                    />
                                                </p>
                                            )
                                        })}
                                    </div>
                                    <div onClick={inviteHandler}
                                        className='flex justify-end'
                                    >
                                        <button disabled={displayEmailData.length === 0}
                                            className="text-[0.8rem] sm:text-[1rem] font-semibold bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[2rem] rounded-md">
                                            Invite
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        isInviteBtnClicked && (
                            <div className="flex flex-col gap-[2rem] p-[1rem]">
                                <div className='flex justify-end'>
                                    <IconButton onClick={() => closeHandler(close)}
                                        className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
                                        size='sm'
                                        style={{
                                            borderRadius: "5px",
                                            border: "1px solid #FF0023",
                                        }}
                                    >
                                        <Icon
                                            icon="material-symbols:close"
                                            className="text-[1.5rem]"
                                        />
                                    </IconButton>
                                </div>
                                <div className='flex flex-col items-center gap-[1rem]'>
                                    <span className='p-[0.5rem] bg-primary rounded-full'>
                                        <Icon
                                            icon="material-symbols:check"
                                            className="text-[2.5rem] text-white"
                                        />
                                    </span>
                                    <h2 className='text-[1.5rem] font-semibold'>
                                        Invitation has been Sent Successfully

                                    </h2>
                                </div>
                            </div>
                        )
                    }
                    {
                        showKeepInvite && (
                            <div className="px-[1rem] flex flex-col gap-[1rem]">
                                <h2 className='text-[1.5rem] font-semibold'>
                                    Close without adding anyone to this channel ?
                                </h2>
                                <div
                                    className='flex justify-end items-center gap-[2rem]'
                                >
                                    <button onClick={keepInviteHandler}
                                        className="text-[0.8rem] sm:text-[1rem] font-bold bg-white text-primary border border-primary py-[0.5rem] px-[0.4rem] xs:px-[2rem] rounded-md">
                                        Keep Invite
                                    </button>
                                    <button onClick={() => closeHandler(close)}
                                        className="text-[0.8rem] sm:text-[1rem] font-semibold bg-primary text-white py-[0.55rem] px-[0.4rem] xs:px-[2rem] rounded-md">
                                        Close
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    {
                        inviteFailed && (
                            <div className="flex flex-col gap-[2rem] p-[1rem]">
                                <div className='flex justify-end'>
                                    <IconButton onClick={() => closeHandler(close)}
                                        className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
                                        size='sm'
                                        style={{
                                            borderRadius: "5px",
                                            border: "1px solid #FF0023",
                                        }}
                                    >
                                        <Icon
                                            icon="material-symbols:close"
                                            className="text-[1.5rem]"
                                        />
                                    </IconButton>
                                </div>
                                <div className='flex flex-col items-center gap-[1rem]'>
                                    <span className='p-[0.5rem] bg-[#F0ACAC] rounded-full'>
                                        <Icon
                                            icon="mdi:dot"
                                            className="text-[2.5rem] text-[#E25B5B]"
                                        />
                                    </span>
                                    <h2 className='text-[1.5rem] font-semibold'>
                                        Oops Invite has been failed Please try again
                                    </h2>
                                </div>
                            </div>
                        )
                    }
                    {/* <div className="px-[1rem] flex flex-col gap-[1rem]">
                  <h2 className='text-[1.5rem] font-semibold'>
                     Close without adding anyone to this channel ?
                  </h2>
                  <div
                     className='flex justify-end items-center gap-[2rem]'
                  >
                     <button onClick={keepInviteHandler}
                        className="text-[0.8rem] sm:text-[1rem] font-bold bg-white text-primary border border-primary py-[0.5rem] px-[0.4rem] xs:px-[2rem] rounded-md">
                        Keep Invite
                     </button>
                     <button onClick={() => closeHandler(close)}
                        className="text-[0.8rem] sm:text-[1rem] font-semibold bg-primary text-white py-[0.55rem] px-[0.4rem] xs:px-[2rem] rounded-md">
                        Close
                     </button>
                  </div>
               </div>
               <div className="">
                  <Popup
                     trigger={<button className="button"> Trigger </button>}
                     position="top center"
                     nested
                     modal
                  >
                     <div className="px-[1rem] flex flex-col gap-[1rem]">
                        <h2 className='text-[1.5rem] font-semibold'>
                           Close without adding anyone to this channel ?
                        </h2>
                        <div
                           className='flex justify-end items-center gap-[2rem]'
                        >
                           <button onClick={keepInviteHandler}
                              className="text-[0.8rem] sm:text-[1rem] font-bold bg-white text-primary border border-primary py-[0.5rem] px-[0.4rem] xs:px-[2rem] rounded-md">
                              Keep Invite
                           </button>
                           <button onClick={() => closeHandler(close)}
                              className="text-[0.8rem] sm:text-[1rem] font-semibold bg-primary text-white py-[0.55rem] px-[0.4rem] xs:px-[2rem] rounded-md">
                              Close
                           </button>
                        </div>
                     </div>
                     <button onClick={close}>Close</button>
                  </Popup>
               </div> */}
                    {/* <Popup
                  trigger={<button className="button"> Trigger </button>}
                  position="top center"
                  nested
                  modal
               >
                  <span>
                     Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae
                     magni omnis delectus nemo, maxime molestiae dolorem numquam
                     mollitia, voluptate ea, accusamus excepturi deleniti ratione
                     sapiente! Laudantium, aperiam doloribus. Odit, aut.
                     <button onClick={close}>CLOSE</button>
                  </span>
               </Popup> */}
                </div>
            )}
        </Popup>
    )
};
