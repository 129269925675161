import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import walletBg from "../../../assets/wallet_card.svg";
import "flatpickr/dist/themes/material_red.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Option,
  Select,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { Link } from "react-router-dom";

export default function WalletList() {
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1400px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const data = [
    {
      leadId: "12345",
      agentName: "Manikanta Putta",
      country: "India",
      currency: "INR",
      status: "Successful",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      agentName: "Vishwanath",
      country: "USA",
      currency: "USD",
      status: "Failed",
      contactInformation: "Viswanath@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Cell",
    },
    {
      leadId: "12345",
      agentName: "Madhu",
      country: "India",
      currency: "INR",
      status: "Pending",
      contactInformation: "madhu@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Website",
    }
  ];
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Unique Number",
      id: "uniqueNumber",
      selector: (row) => <h1 className="underline">A123GD5HGN</h1>,
      center: true,
      grow: 2,
    },
    {
      name: "Lottery Name",
      id: "lotteryName",
      selector: (row) => "Withdraw",
      center: true,
      grow: 2,
    },
    {
      name: "Winning Amount",
      id: "currency",
      selector: (row) => "500",
      center: true,
      grow: 2,
    },
    {
      name: "Expected Date",
      id: "expectedDate",
      selector: (row) => moment(row.expieryDate).format("DD MMMM YYYY"),
      center: true,
      grow: 2,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold px-[1rem]
        py-[0.5rem] ${
          row.status === "Failed"
            ? "text-[#FF0023] bg-[#FDCAD1]"
            : row.status === "Successful"
            ? "text-[#25BF17] bg-[#D1F0CF]"
            : "text-[#FFCE06] bg-[#FDF9EF]"
        }`}
        >
          <span
            className={`${
              row.status === "Failed"
                ? "bg-[#FF0023]"
                : row.status === "Successful"
                ? "bg-[#25BF17]"
                : "bg-[#FFCE06]"
            } h-[0.5rem] w-[0.5rem] rounded-full`}
          ></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 3,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex space-x-2">
          <Link to={``}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon icon="mdi:eye" className="text-[1.5rem]" />
            </IconButton>
          </Link>
        </div>
      ),
      center: true,
    },
  ];

  const handleDateChange = (selectedDates) => {
    console.log(selectedDates.length);

    if (selectedDates.length === 1) {
      const formatedStartDate = moment(selectedDates[0]).format("YYYY-MM-DD");
      setStartDate(formatedStartDate);
    }
    if (selectedDates.length === 2) {
      const formatedEndDate = moment(selectedDates[1]).format("YYYY-MM-DD");
      setEndDate(formatedEndDate);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "Y-m-d",
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between mb-[2rem]">
        <h1 className="text-[1.2rem] sm:text-[1.4rem] font-semibold whitespace-nowrap">
          Wallet
        </h1>
        <div className="">
          <Link to={"create"}>
            <button
              disabled
              className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md"
            >
              <span className="whitespace-nowrap">Withdraw</span>
              <Icon
                icon="akar-icons:arrow-right"
                className="text-[1.2rem] sm:text-[1.5rem]"
              />
            </button>
          </Link>
        </div>
      </div>

      {/* Wallet Banner  */}
      <div
        style={{
          backgroundImage: `url(${walletBg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
        className="md:w-[714px] sm:w-[500px] w-[300px] h-[220px] rounded-[20px] p-5 mb-[2.5rem]"
      >
        <div className="flex sm:flex-row flex-col-reverse justify-between">
          <div className="flex flex-col justify-center h-[200px] sm:py-5 py-2">
            <div className="flex items-center gap-16">
              <p className="text-[20px] text-[#FFF]">Balance:</p>
            </div>
            <h1 className="text-[#FFF] text-[50px] font-bold mt-3">19000</h1>
          </div>
        </div>
      </div>

      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.533975px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search here..."
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center my-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1.2rem] font-semibold">List</h1>
        <div className="flex gap-[1rem] items-center px-[1rem]">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.533975px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search here..."
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            <button
              onClick={() => setSearchKey("")}
              className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md"
            >
              <Icon icon="akar-icons:cross" className="text-white" />
            </button>
          </div>
          <div className="max-md:w-full">
            <Flatpickr
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none py-[0.5rem] px-[1rem]"
              style={{
                border: "0.533975px solid #DFDFDF",
                borderRadius: "6px",
              }}
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
          </div>
          <div className="">
            <Select
              label="Select Country"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent"
            >
              <Option value="India">India</Option>
              <Option value="UAE">UAE</Option>
              <Option value="England">England</Option>
            </Select>
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <DataTable
          columns={columns}
          data={data}
          customStyles={customStyles}
          pagination
          // paginationComponent={() =>
          //   CustomPagination({
          //     rowsPerPage: 10,
          //     rowCount: 12,
          //     currentPage: page,
          //     onChangePage: setPage,
          //   })
          // }

          // progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}
