import React, { useState } from "react";
import { Icon } from "@iconify/react";
//import vendorAvatar from "../../assets/vendorAvatar.svg";
// import Badge from "@mui/material/Badge";
// import { useSelector } from "react-redux";
import adminAvatar from "../../assets/adminAvatar.svg";
import Avatar from 'react-avatar';
import { useAuth } from "../../store/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const MobileHeader = ({ isAdminSidebarOpen, setIsAdminSidebarOpen }) => {
//   const {userId, userName} = useSelector((state)=>state.employee);
const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  return (
    <div className="sm:hidden grid grid-cols-1 gap-[1rem] px-[1rem] py-[1rem] border-b border-[#DFDFDF] font-workSans">
      <div className="flex flex-row justify-between items-center gap-[2rem] md:gap-[4rem]">
        <div
          className="px-[0.3rem] rounded-md lg:hidden bg-primary"
          // style={{
          //   background:
          //     "linear-gradient(91.06deg, #BE0A23 0.01%, #E99F00 100.04%)",
          // }}
          onClick={()=>{setIsAdminSidebarOpen(true)}}
        >
          <Icon icon="ic:outline-menu" className="text-[2rem] text-white" />
        </div>
        
      {/* <div className="flex flex-row items-center gap-[2rem]">
      <div className="bg-[#F6F6F8] p-[0.5rem] rounded-full">
          <Icon icon="clarity:notification-line" className="text-[1.2rem]" />
        </div>

        <div className="flex flex-row items-center gap-[1rem]">
        <img src={adminAvatar} alt="" className="" />
          <img src={vendorAvatar} alt="" className="w-[2.5rem] h-auto" />
          <div>
            <h3 className="text-[#4D4D4D] text-[0.8rem] font-[600]">
              {"Manikanta"}
            </h3>
            <p className="text-[#4D4D4D] text-[0.7rem] font-[500]">{"Admin"}</p>
          </div>
        </div>
      </div> */}

        {/* Profile Info  */}
        <div className="relative">
          <div onClick={()=>setIsDropdownVisible(!isDropdownVisible)} className="flex flex-row items-center gap-[1rem] cursor-pointer select-none">
            <Avatar
              name={
                userDetails?.FirstName + " " + userDetails?.LastName || "NA"
              }
              size="40"
              round={true}
            />
            <div>
              <h3 className="text-[#4D4D4D] text-[0.8rem] font-[600]">
                {userDetails?.FirstName + " " + userDetails?.LastName || "NA"}
              </h3>
              <p className="text-[#4D4D4D] text-[0.7rem] font-[500]">
                Wallet Agent
              </p>
            </div>
            {
            isDropdownVisible ? <Icon className="text-2xl" icon="iconamoon:arrow-up-2-light" /> : <Icon className="text-2xl" icon="iconamoon:arrow-down-2-light" />
          }
          </div>

          {/* Dropdown  */}
        {
          isDropdownVisible && (<ul className="absolute mt-4 shadow-md border-[1px] bg-white border-gray-300 py-3 min-w-[170px] space-y-3">
            <Link to="/lottery-agent/profile">
            <li onClick={()=>setIsDropdownVisible(false)} className="hover:bg-gray-300 cursor-pointer p-2 flex items-center gap-2">
            <Icon className="text-[20px]" icon="clarity:user-line" />
              <span>Profile</span>
            </li>
            </Link>
            <li onClick={()=>setIsDropdownVisible(false)} className="hover:bg-gray-300 cursor-pointer p-2 flex items-center gap-2 text-red-500">
            <Icon className="text-[20px]" icon="material-symbols:logout" />
              <span>Sign Out</span>
            </li>
          </ul>)
        }
        </div>



      </div>
      <div>

      <div
          className="flex flex-row justify-between items-center border rounded-md border-[#DFDFDF] bg-white px-[1rem] py-[0.5rem]"
          style={{ boxShadow: "4px 4px 150px rgba(0, 0, 0, 0.13)" }}
        >
          <input
            type="text"
            className="flex-1 placeholder-[#848484] text-[#848484] text-[0.9rem] outline-none"
            placeholder="Search..."
          />
          <div
            className="rounded-md h-full text-white bg-primary p-[0.3rem] text-sm cursor-pointer"
            // style={{
            //   background:
            //     "linear-gradient(91.06deg, #BE0A23 0.01%, #E99F00 100.04%)",
            // }}
          >
            <Icon icon="akar-icons:search" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
