import { configureStore } from "@reduxjs/toolkit";
import createAgentSlice from "./createAgentSlice";
import { agentApiSlice } from "./agentApiSlice";
import OTPSlice from "./OTPSlice";
import notificationSlice from "./notificationSlice";

const store = configureStore({
  reducer: {
    createAgent: createAgentSlice,
    notification: notificationSlice,
    OTP: OTPSlice,
    [agentApiSlice.reducerPath]: agentApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(agentApiSlice.middleware),
});

export default store;
