import React from 'react'

const Test = () => {
  return (
    <div>
      <h1>Component For Testing...</h1>
    </div>
  )
}

export default Test
