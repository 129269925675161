import React, { useEffect } from 'react'
import { Icon } from '@iconify/react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
// import userImg from '../../assets/user-img.png'
import { useQuery } from 'react-query';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import moment from 'moment';
import { animateScroll as scroll } from "react-scroll";
import userImg from '../../../assets/user-img.png'


export default function ViewPrivateAgent() {

   const { id } = useParams();
   const navigate = useNavigate();
   const { getAccessToken } = useAuth()

   function formatToIndianCurrency(number) {
      return number?.toLocaleString('en-IN', {
         // style: 'currency',
         currency: 'INR'
      });
   }

   // const fetchLotteryDataById = async () => {

   //     const token = await getAccessToken()

   //     /// console.log("Lottary",token)
   //     const response = await fetch(`${APIurls.fetchLottaryById}/${id}`, {
   //         method: "GET",
   //         headers: {
   //             Authorization: `Bearer ${token}`,
   //         }
   //     });
   //     if (!response.ok) {
   //         throw new Error("Failed to Fetch User Data");
   //     }
   //     const result = await response.json();
   //     return result.response;
   // };

   // const { data: lotteryData, error, isLoading } = useQuery('lotteryData', fetchLotteryDataById);

   // console.log("SINGLE LOTTERY DATA", lotteryData?.LottaryData);


   return (
      <div className='flex flex-col gap-[2rem] my-[1rem] px-[1rem]'>
         <div className='flex justify-between'>
            <button
               onClick={() => navigate("/admin/private-agents")}
               className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
            >
               <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
               <span>Back</span>
            </button>
            <div className='flex gap-[1rem]'>
               <button
                  onClick={() => navigate(-1)}
                  className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[2rem] rounded-md"
               >
                  Edit
               </button>
               <button
                  onClick={() => navigate(-1)}
                  className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-[#FF0023] py-[0.4rem] px-[1rem] rounded-md"
               >
                  Block User
               </button>
            </div>
         </div>
         <div className='h-[155px] flex gap-[1rem]'>
            <div>
               <img src={userImg} alt="userImg" className='rounded-xl h-[155px] w-[170px] bg-cover bg-center' />
            </div>
            <div className='flex flex-col justify-between gap-[1rem] p-[1rem] rounded-xl'
               style={{
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
               }}
            >
               <div className='flex gap-[2rem] items-center'>
                  <div className='bg-[#D1F0CF] p-[0.1rem] rounded-md'>
                     <Icon icon="heroicons-solid:user-group" className='text-primary text-[2rem]' />
                  </div>
                  <div>
                     <h1 className='font-bold whitespace-nowrap'>MD Mustaq Ahmed
                     </h1>
                     <p className='text-[#8B8D97] text-[0.8rem] whitespace-nowrap'>Created on{" "}
                        <span className='font-bold'>
                           {moment(Date.now()).format("DD MMM YYYY")}
                        </span>
                     </p>
                  </div>
                  <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[7.5rem] py-[0.3rem] ${"ACTIVE" === "PENDING" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#25BF17] bg-[#D1F0CF]"}`}>
                     <span className={`${"ACTIVE" === "PENDING" ? "bg-[#FF0023]" : "bg-[#25BF17]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                     <h2>ACTIVE</h2>
                  </div>
               </div>
               <div className='flex items-center justify-between'>
                  <div>
                     <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                        Email ID
                     </label>
                     <h2 className='font-semibold'>
                        manikantaputta@gmail.com
                     </h2>
                  </div>
                  <div>
                     <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                        Mobile Number
                     </label>
                     <h2 className='font-semibold'>
                        9876543210
                     </h2>
                  </div>
               </div>
            </div>
            <div className='flex flex-wrap justify-between items-center gap-[1rem] p-[1rem] rounded-xl'
               style={{
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
               }}
            >
               <div>
                  <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                     Nationality
                  </label>
                  <h2 className='font-semibold'>Indian</h2>
               </div>
               <div>
                  <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>
                     Passport Number
                  </label>
                  <h2 className='font-semibold'>
                     ABC123ASD
                  </h2>
               </div>
               <div>
                  <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                     WhatsApp Number
                  </label>
                  <h2 className='font-semibold'>
                     9876543210
                  </h2>
               </div>
               <div>
                  <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>
                     Address
                  </label>
                  <h2 className='font-semibold'>
                     Hyderabad, Telangana, India - 500074
                  </h2>
               </div>
            </div>
         </div>
         <div>
            <div className='flex gap-[2rem] text-[1.2rem] border-b-2 border-[#989898]'>
               <NavLink to={`/admin/private-agents/view/:id`} end>
                  {({ isActive }) => (
                     <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                        All
                     </h2>
                  )}
               </NavLink>
               <NavLink to={`/admin/private-agents/view/:id/recharge`}>
                  {({ isActive }) => (
                     <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                        Recharge
                     </h2>
                  )}
               </NavLink>
               <NavLink to={`/admin/private-agents/view/:id/withdrawal`}>
                  {({ isActive }) => (
                     <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                        Withdrawal
                     </h2>
                  )}
               </NavLink>
               <NavLink to={`/admin/private-agents/view/:id/pending`}>
                  {({ isActive }) => (
                     <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                        Pending
                     </h2>
                  )}
               </NavLink>
               <NavLink to={`/admin/private-agents/view/:id/invited`}>
                  {({ isActive }) => (
                     <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                        Invited
                     </h2>
                  )}
               </NavLink>
            </div>
            <Outlet />
         </div>
      </div>
   )
}

