import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

export default function AgentsContainer() {
    return (
        <div>
            <div className='flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[1rem]'>
                <NavLink to={`/admin/agents`} end>
                    {({ isActive }) => (
                        <h2 className={`text-[1.2rem] sm:text-[1.5rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Agents
                        </h2>
                    )}
                </NavLink>
                <NavLink to={`/admin/agents/request`}>
                    {({ isActive }) => (
                        <h2 className={`text-[1.2rem] sm:text-[1.5rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Agents Requests
                        </h2>
                    )}
                </NavLink>
            </div>
            <Outlet />
        </div>
    )
}
