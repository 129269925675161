import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { APIurls } from "../../api/apiConstant";
import { useAuth } from "../../store/AuthContext";
import { ThreeDots } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import { RotatingLines } from 'react-loader-spinner';
import moment from "moment/moment";


export default function EditLottery() {
  const [selectImg, setSelectImg] = useState();
  const navigate = useNavigate();
  const [selectDate, setSelectDate] = useState(null);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, control, reset } = useForm();
  const [fetchLoaderBtn, setFetchLoaderBtn] = useState(false);

  const [winnerSlotsdata, setWinnerslot] = useState(0)
  const { getAccessToken } = useAuth()
  const { id } = useParams();

  const submitWinnerSlot = (num) => {
    if (num < 0) {
      return setWinnerslot(0)
    }
    if (num > 0) {
      const data = {
        winnerNum: 0
      }
      setWinnerslot(num)
    }
  }

  const fetchLotteryById = async () => {
    setFetchLoaderBtn(true);
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchLottaryById}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Error Fetching Private Lottery Data");
      }
      const result = await response.json();
      const {
        Name, LottaryNumber, Totaltickets, ticketPrice, winneramount, termsandcondi, expieryDate, lottaryImage
      } = result?.response?.LottaryData;
      setValue("name", Name);
      setValue("LottaryNumber", LottaryNumber);
      setValue("totalTickets", Totaltickets);
      setValue("pricePerTicket", ticketPrice);
      setValue("totalWinningAmount", winneramount);
      setValue("termsAndConditions", termsandcondi);
      const formattedDate = moment(expieryDate).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
      setSelectDate(formattedDate)
      setSelectImg(lottaryImage);
      console.log("Lottery Data", result?.response?.LottaryData);
    } catch (error) {
      console.warn("Error Fetching Lottery Data");
      toast.error("Error Fetching Lottery Data");
    }
    setFetchLoaderBtn(false);
  }

  useEffect(() => {
    fetchLotteryById();
  }, [])

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const { name, LottaryNumber, totalTickets, pricePerTicket, termsAndConditions } = data;
    const formData = new FormData();
    formData.append("lotteryNumber", LottaryNumber);
    formData.append("lotteryName", name);
    formData.append("totalNoOfTickets", totalTickets);
    formData.append("pricePET", pricePerTicket);
    formData.append("expDrawDate", selectDate);
    formData.append("termsandcond", termsAndConditions);
    if (selectImg) {
      formData.append("lottaryImage", selectImg);
    }
    const token = await getAccessToken()
    const requestOptions = {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    try {
      setLoaderBtn(true)
      const response = await fetch(`${APIurls.updateLottary}/${id}`, requestOptions);

      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          const result = await response.json();
          console.error("Error Updating Lottery:", result.error);
          console.log(result?.message)
          toast.error(result?.message)
        } else {
          console.log(response.status)
          throw new Error("Error Updating Lottery");
        }
      } else {
        setLoaderBtn(false)
        const result = await response.json();
        setSelectDate(null);
        setSelectImg("");
        toast.success("Successfully Updated Lottery !");
        navigate('/lottery-agent/lottery');
        console.log(result);
        reset();
      }
    } catch (error) {
      setLoaderBtn(false)
      toast.error("Error Updating Lottery")
      console.warn(error);
    }
  }

  const handleDateChange = (selectedDate) => {
    setSelectDate(selectedDate[0]);
  };
  const dateOptions = {
    mode: "single",
    dateFormat: "d M Y",
  };

  if (fetchLoaderBtn) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[1rem] px-0 sm:px-[1rem] font-[500]">
      <div className="text-primary flex items-center gap-[1rem] px-[1rem]">
        <Icon icon="game-icons:ticket" className="text-[2rem]" />
        <h1 className="text-[1.2rem] sm:text-[1.7rem] font-semibold">
          Edit Lottery
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}
        className="bg-white flex flex-col gap-[2rem] px-[2rem] py-[1rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="nameOfLottery" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Name of Lottery
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Name"
              {...register("name", {
                required: "*This field is required.",
              })}
            />
            {errors.name && (
              <div className="text-sm text-[#E92215]">
                {errors.name.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="lottaryNumber" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Unique Number
              {/* <span className="text-[#E92215]">*</span> */}
            </label>

            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none bg-gray-200 cursor-not-allowed"
              type="text"
              readOnly
              placeholder="Enter Unique Number"
              {...register("LottaryNumber", {
                required: "*This field is required.",
              })}
            />
            {errors.uniqueNumber && (
              <div className="text-sm text-[#E92215]">
                {errors.uniqueNumber.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="totalTickets" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Total Number Of Tickets
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Eg: 500"
              {...register("totalTickets", {
                required: "*This field is required.",
              })}
            />
            {errors.totalTickets && (
              <div className="text-sm text-[#E92215]">
                {errors.totalTickets.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="pricePerTicket" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Price Per Each Ticket
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Eg: 300"
              {...register("pricePerTicket", {
                required: "*This field is required.",
              })}
            />
            {errors.pricePerTicket && (
              <div className="text-sm text-[#E92215]">
                {errors.pricePerTicket.message}
              </div>
            )}
          </div>
          {/* <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="totalWinningAmount" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Total Winning Amount
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Eg: 50,000"
            // value={"50,000"}
            // readOnly
            />
          </div> */}
          {/* <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="numOfWinners" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Number Of Winners
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Eg: 2"
              onClick={(e) => {
                submitWinnerSlot(e.target.value)
              }}
            // value={"2"}
            // readOnly
            />
          </div> */}

          {/* {
            Array.from({ length: winnerSlotsdata }).map((data, index) => (

              <div className="flex flex-col gap-[0.5rem]">
                <label htmlFor="winner1" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                  Winner {index + 1}
                </label>
                <input
                  className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                  type="text"
                  placeholder="Eg: 30,000"
                // value={"30,000"}
                // readOnly
                />
              </div>
            ))} */}


          {/* <div className="flex flex-col gap-[0.5rem]">
                        <label htmlFor="winner2" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                            Winner 2
                        </label>
                        <input
                            className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                            type="text"
                            placeholder="Eg: 20,000"
                            // value={"20,000"}
                            // readOnly
                        />
                    </div> */}
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="expectedDateOfDraw" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Expected Date Of Draw
            </label>
            <Flatpickr
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              placeholder="Select Expected Date"
              value={selectDate}
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Terms & Conditions
            </label>
            <textarea
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              rows={3}
              placeholder="Description goes here"
              {...register("termsAndConditions", {
                required: "*This field is required.",
              })}
            />
            {errors.termsAndConditions && (
              <div className="text-sm text-[#E92215]">
                {errors.termsAndConditions.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="profilePicture" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Profile Picture
            </label>
            <div className="flex flex-col items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
              {selectImg ? (
                <>
                  {
                    selectImg?.name ? (
                      <img src={URL.createObjectURL(selectImg)} alt="" className='h-[150px] w-[200px] rounded-md' />
                    ) : (
                      <img src={selectImg} alt="" className='h-[150px] w-[200px] rounded-md' />
                    )
                  }
                  <div className="flex gap-[1rem] items-center mt-2">
                    <h2 className="text-[1.1rem] text-black font-[500]">
                      {selectImg?.name?.length > 30
                        ? selectImg.name.substring(0, 30) + "..."
                        : selectImg.name}
                    </h2>
                    <Icon
                      onClick={() => setSelectImg(null)}
                      icon="akar-icons:cross"
                      className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                    />
                  </div>
                </>
              ) : (
              <>
                <button
                  type="button"
                  onClick={() =>
                    document.querySelector(".input-field").click()
                  }
                >
                  <Icon
                    icon="material-symbols:cloud-upload"
                    className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                  />
                </button>
                <input
                  type="file"
                  // accept="image/*"
                  value=""
                  className="input-field"
                  hidden
                  onChange={({ target: { files } }) => {
                    if (files[0]) {
                      setSelectImg(files[0]);
                    }
                  }}
                />
                <p className="text-[0.9rem] text-[#D9D9D9]">
                  Upload jpg, png, pdf. Size up to 20MB
                </p>
              </>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-[2rem] items-center justify-end">
          <button onClick={() => navigate(-1)}
            className="text-[0.9rem] sm:text-[1.2rem] font-semibold text-primary bg-white borde border-primary py-[0.4rem] w-[10rem] sm:w-[12rem] rounded-[8px]"
            type="button"
          >
            Back
          </button>

          {loaderBtn ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#9FB947"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) :
            (<button
              className="text-[0.9rem] sm:text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] w-[10rem] sm:w-[12rem] rounded-[8px]"
              type="submit"
            >
              Save Lottery
            </button>)}

        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

