import React, { useState } from 'react'
import PersonalDetails from './PersonalDetails';
import BankDetails from './BankDetails';
import KYCDetails from './KYCDetails';
import HeaderStepper from '../../../helpers/HeaderStepper';

export default function CreateAgent() {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState(["Personal Details", "Bank Details", "KYC Details"])

  const displayStep = () => {
    switch (currentStep) {
      case 1: return <PersonalDetails currentStep={currentStep} handleClick={handleClick} />;
      case 2: return <BankDetails currentStep={currentStep} handleClick={handleClick} />;
      case 3: return <KYCDetails />;
      default: break;
    }
  }

  const handleClick = async (direction) => {
    let newStep = currentStep;
    if (direction === "next") {
      newStep++;
    } else if (direction === "back") {
      newStep--;
    }

    if (newStep > 0 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }
  }

  return (
    <div>
      <div>
        <HeaderStepper steps={steps} currentStep={currentStep} />
      </div>
      <div>
        {displayStep()}
      </div>
    </div>
  )
}
