import React, { useEffect } from 'react'
import { Icon } from '@iconify/react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
// import userImg from '../../assets/user-img.png'
import { useQuery } from 'react-query';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import moment from 'moment';
import { animateScroll as scroll } from "react-scroll";



export default function ViewPrivateLotteries() {

    const { id } = useParams();
    const navigate = useNavigate();
    const { getAccessToken } = useAuth()

    function formatToIndianCurrency(number) {
        return number?.toLocaleString('en-IN', {
            // style: 'currency',
            currency: 'INR'
        });
    }

    const fetchLotteryDataById = async () => {

        const token = await getAccessToken()

        /// console.log("Lottary",token)
        const response = await fetch(`${APIurls.fetchLottaryById}/${id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            throw new Error("Failed to Fetch User Data");
        }
        const result = await response.json();
        return result.response;
    };

    const { data: lotteryData, error, isLoading } = useQuery('lotteryData', fetchLotteryDataById);

    console.log("SINGLE LOTTERY DATA", lotteryData?.LottaryData);

    useEffect(() => {
        scroll.scrollToTop();
        console.log("USE EFFECT")
    }, [])

    return (
        <div className='flex flex-col gap-[2rem] my-[1rem] px-[1rem]'>
            <div className='flex justify-between'>
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
                >
                    <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
                    <span>Back</span>
                </button>
                <button
                    onClick={() => navigate(-1)}
                    className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
                >
                    Announce Winner
                </button>
            </div>
            <div className='h-[155px] flex gap-[1rem]'>
                <div>
                    <img src={lotteryData?.LottaryData?.lottaryImage} alt="userImg" className='rounded-xl h-[155px] w-[170px] bg-cover bg-center' />
                </div>
                <div className='flex flex-col justify-between gap-[1rem] p-[1rem] rounded-xl'
                    style={{
                        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                    }}
                >
                    <div className='flex gap-[2rem] items-center'>
                        <div className='bg-[#D1F0CF] p-[0.1rem] rounded-md'>
                            <Icon icon="mdi:user" className='text-primary text-[2rem]' />
                        </div>
                        <div>
                            <h1 className='font-bold whitespace-nowrap'>{lotteryData?.LottaryData?.Name}
                            </h1>
                            <p className='text-[#8B8D97] text-[0.8rem] whitespace-nowrap'>Created on{" "}
                                <span className='font-bold'>
                                    {moment(lotteryData?.LottaryData?.createdAt).format("DD MMM YYYY")}
                                </span>
                            </p>
                        </div>
                        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[7.5rem] py-[0.3rem] ${"ACTIVE" === "PENDING" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#25BF17] bg-[#D1F0CF]"}`}>
                            <span className={`${"ACTIVE" === "PENDING" ? "bg-[#FF0023]" : "bg-[#25BF17]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                            <h2>ACTIVE</h2>
                        </div>
                    </div>
                    <div className='flex items-center justify-between'>
                        <div>
                            <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                                Expected Date Of Draw
                            </label>
                            <div className='flex items-center gap-[1rem]'>
                                <Icon icon="material-symbols:date-range-outline" className='text-[1.5rem]' />
                                <h2 className='font-semibold'>
                                    {moment(lotteryData?.LottaryData?.expieryDate).format("DD MMM YYYY")}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-3 gap-[1rem] p-[1rem] rounded-xl'
                    style={{
                        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                    }}
                >
                    <div>
                        <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>Total Number Of Tickets</label>
                        <h2 className='font-semibold'>{lotteryData?.LottaryData?.Totaltickets}</h2>
                    </div>
                    <div>
                        <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>Price Per Each Ticket</label>
                        <h2 className='font-semibold'>
                            {lotteryData?.LottaryData?.Currency} {lotteryData?.LottaryData?.ticketPrice}
                        </h2>
                    </div>
                    <div>
                        <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>Total Winning Amount</label>
                        <h2 className='font-semibold'>
                            {lotteryData?.LottaryData?.Currency} {formatToIndianCurrency(lotteryData?.LottaryData?.winneramount)}
                        </h2>
                    </div>
                    <div>
                        <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>Number Of Winners</label>
                        <h2 className='font-semibold'>
                            {lotteryData?.LottaryData?.winnerSlot}
                        </h2>
                    </div>
                    <div>
                        <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>Winner 1</label>
                        <h2 className='font-semibold'>30,000</h2>
                    </div>
                    <div>
                        <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>Winner 2</label>
                        <h2 className='font-semibold'>20,000</h2>
                    </div>
                </div>
            </div>
            <div>
                <div className='flex gap-[2rem] text-[1.2rem] border-b-2 border-[#989898]'>
                    <NavLink to={`/admin/private-lotteries/view/${id}`} end>
                        {({ isActive }) => (
                            <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                                Users
                            </h2>
                        )}
                    </NavLink>
                    <NavLink to={`/admin/private-lotteries/view/${id}/wishlist`}>
                        {({ isActive }) => (
                            <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                                Wishlist
                            </h2>
                        )}
                    </NavLink>
                    <NavLink to={`/admin/private-lotteries/view/${id}/invited`}>
                        {({ isActive }) => (
                            <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                                Invited
                            </h2>
                        )}
                    </NavLink>
                    <NavLink to={`/admin/private-lotteries/view/${id}/winners`}>
                        {({ isActive }) => (
                            <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                                Winners
                            </h2>
                        )}
                    </NavLink>
                </div>
                <Outlet />
            </div>
        </div>
    )
}

