import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Option,
  Select,
  ThemeProvider,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../helpers/CustomCheckbox";
import moment from "moment";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';
import { APIurls } from "../../api/apiConstant";
import { useAuth } from "../../store/AuthContext";

export default function LotteryList() {
  const [status, setStatus] = useState("ALL");
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const flatpickrRef = useRef();
  //const [lotteryData, setLotteryData] = useState([]);
  const { getAccessToken } = useAuth();

  function formatToIndianCurrency(number) {
    return number?.toLocaleString('en-IN', {
      currency: 'INR'
    });
  }

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1400px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const data = [
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Vishwanath",
      contactInformation: "Viswanath@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Cell",
    },
    {
      leadId: "12345",
      clientName: "Madhu",
      contactInformation: "madhu@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Website",
    },
    {
      leadId: "12345",
      clientName: "Sudheer",
      contactInformation: "sudheer@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
    {
      leadId: "12345",
      clientName: "Manikanta Putta",
      contactInformation: "manikantaputta@gmail.com 9876543210",
      leadDate: "2022-09-09 14:07:55",
      technology: "Email",
    },
  ];
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Unique Number",
      id: "uniqueNumber",
      selector: (row) => row.LottaryNumber,
      center: true,
      grow: 3,
    },
    {
      name: "Lottery Name",
      id: "lotteryName",
      selector: (row) => row.Name,
      center: true,
      grow: 2,
    },
    {
      name: "Winning Amount",
      id: "winningAmount",
      selector: (row) => row.winneramount.toString(),
      center: true,
      grow: 3,
    },
    {
      name: "Expected Date",
      id: "expectedDate",
      selector: (row) => moment(row.expieryDate).format("DD MMMM YYYY"),
      center: true,
      grow: 3,
    },
    {
      name: "Image",
      id: "image",
      selector: (row) => (
        <div className="rounded-lg">
          <img src={row.lottaryImage} alt="" className="h-[50px] w-[120px] bg-center" />
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[8rem] 
        py-[0.6rem] ${row.status === "PENDING" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#25BF17]"}`}>
          <span className={`${row.status === "PENDING" ? "bg-[#FF0023]" : "bg-[#25BF17]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 3,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex space-x-2">
          <Link to={`view/${row.UniqueID}`}
          >
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon icon="mdi:eye" className="text-[1.5rem]" />
            </IconButton>
          </Link>
          <Link to={`edit/${row.UniqueID}`}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem]"
              />
            </IconButton>
          </Link>
          <Link to={"edit/:id"}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon
                icon="material-symbols:delete-rounded"
                className="text-[1.5rem]"
              />
            </IconButton>
          </Link>
        </div>
      ),
      center: true,
      grow: 2,
    },
  ];

  const fetchLotteryData = async () => {

    const token = await getAccessToken()

    /// console.log("Lottary",token)
    const response = await fetch(APIurls.fetchLottary, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Lottery Data");
    }
    const result = await response.json();
    console.log("LOTTERY DATA", result?.response?.AgentData)
    return result?.response?.AgentData?.reverse();
  };

  const { data: lotteryData, isLoading } = useQuery('lotteries', fetchLotteryData);

  let lotteryCompleted = useMemo(() => (
    lotteryData?.filter((lottery) => lottery?.status.toLowerCase() === "completed")
  ), [lotteryData]);
  let lotteryPending = lotteryData?.length - lotteryCompleted?.length;

  const handleDateChange = (selectedDates) => {
    console.log(selectedDates.length);

    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "Y-m-d",
  };

  useEffect(() => {
    let mapData = lotteryData?.map((item, index) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        return item?.Name?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      console.log("DATE...........DATE...........DATE");
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.expieryDate);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
        );
      });
    }
    if (status === "ALL") {
      mapData = mapData = lotteryData?.map((item) => ({ ...item }));
    } else {
      mapData = mapData?.filter(item => item.status === status);
    }
    setFilteredItems(mapData);
  }, [searchKey, startDate, endDate, lotteryData, status]);

  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();  // only clear's the date from input field
  }

  const revenueCalculate = (totalTicket, ticketPrice, winningAmount) => {
    return Math.max((Number(totalTicket) * Number(ticketPrice) - Number(winningAmount)), 0)
  }

  let revenue = useMemo(() => (
    lotteryData?.reduce((total, data) => {
      return total + revenueCalculate(data?.Totaltickets, data?.ticketPrice, data?.winneramount)
    }, 0)
  ), [lotteryData]);

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between mb-[1.5rem]">
        <h1 className="text-[1.2rem] sm:text-[1.5rem] font-semibold whitespace-nowrap">
          Manage Lottery
        </h1>
        <div className="flex flex-col xs:flex-row items-start xs:items-center gap-0 xs:gap-[1.5rem] max-xs:mb-[1rem]">
          <Link to={"create"}>
            <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
              <Icon
                icon="material-symbols:add"
                className="text-[1.2rem] sm:text-[1.5rem]"
              />
              <span className="whitespace-nowrap">Create New Lottery</span>
            </button>
          </Link>
        </div>
      </div>
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mt-4 mb-8">
        {/* badge -1: Total Lotteries  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Total Lotteries</h3>
              <Icon className='text-[#25BF17] text-[1.9rem]' icon="game-icons:ticket" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {lotteryData?.length || "NA"}
            </h3>
          </div>
        </div>
        {/* badge -2: Completed Lotteries  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Completed</h3>
              <Icon icon="icon-park-solid:check-one" className="text-primary text-[1.9rem]" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {lotteryCompleted?.length !== "undefined" ? lotteryCompleted?.length : "NA"}
            </h3>
          </div>
          <div>
          </div>
        </div>
        {/* badge -3: Pending Lotteries  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Pending</h3>
              <Icon className='text-[#25BF17] text-[1.6rem]' icon="grommet-icons:in-progress" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold mb-5'>
              {lotteryPending}
            </h3>
          </div>
        </div>
        {/* badge -4: Total Earned  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between items-center'>
              <h3 className='text-[14px] text-[#858585] mb-2'>Total Revenue</h3>
            </header>
            <div className="flex justify-between items-center">
              <h3 className='text-[#25BF17] text-[26px] font-bold'>₹{formatToIndianCurrency(revenue)}</h3>
              <div className="flex items-center gap-2">
                <Icon icon="twemoji:flag-india" />
                <span className="text-[11px]">INR</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                  <path d="M9.25 4.5L6.25 7.5L3.25 4.5" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="flex justify-between gap-[1rem]">
        <h1 className="text-[1.1rem] sm:text-[1.4rem] font-semibold">
          List
        </h1>
        <div className="flex max-xs:items-start gap-[0.5rem] sm:gap-[1rem] justify-center sm:justify-end items-center pb-[1rem] xl:mx-[1rem]">
          <div
            className="flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by Lottery Name"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            <button onClick={() => setSearchKey("")}
              className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
              <Icon icon="akar-icons:cross" className="text-white" />
            </button>
          </div>
          <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none bg-red-500"
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            <button onClick={clearDatesHandler}
              className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
              <Icon icon="akar-icons:cross" className="text-white" />
            </button>
          </div>
          <div className="relative">
            <Select label="Select Status"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent absolute z-50"
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value="ALL">ALL</Option>
              <Option value="PENDING">PENDING</Option>
              <Option value="COMPLETED">COMPLETED</Option>
            </Select>
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-white">
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}

