import { createBrowserRouter } from "react-router-dom";
import AdminLogin from "../pages/login/AdminLogin";
import ForgotPassword from "../pages/login/ForgotPassword";
import AdminLayout from "../layouts/AdminLayout";
import ChangePassword from "../pages/login/ChangePassword";
import OTPVerfication from "../pages/login/OTPVerfication";
import Success from "../pages/login/Success";
import Failure from "../pages/login/Failure";
import Welcome from "../pages/Welcome";
import Dashboard from "../components/adminPanel/Dashboard";
import CreateLottery from "../components/adminPanel/CreateLottery";
import AdminPanelLottery from "../components/adminPanel/AdminPanelLottery";
import LotteryList from "../components/adminPanel/LotteryList";
import { useAuth } from "../store/AuthContext"
import { Navigate } from "react-router-dom"
import AdminPanelUsers from "../components/adminPanel/AdminPanelUsers";
import UsersList from "../components/adminPanel/UsersList";
import UserView from "../components/adminPanel/UserView";
import UserEdit from "../components/adminPanel/UserEdit";
import UserViewTranscations from "../components/adminPanel/UserViewTranscations";
import UserViewWinnings from "../components/adminPanel/UserViewWinnings";
import LotteryView from "../components/adminPanel/LotteryView";
import LotteryViewUsers from "../components/adminPanel/LotteryViewUsers";
import LotteryViewWishlist from "../components/adminPanel/LotteryViewWishlist";
import LotteryViewWinner from "../components/adminPanel/LotteryViewWinner";
import AdminPanelPrivateLotteries from "../components/adminPanel/AdminPanelPrivateLotteries";
import PrivateLotteriesList from "../components/adminModule/PrivateLotteries/PrivateLotteriesList";
import CreatePrivateLotteries from "../components/adminModule/PrivateLotteries/CreatePrivateLotteries";
import EditPrivateLotteries from "../components/adminModule/PrivateLotteries/EditPrivateLotteries";
import ViewPrivateLotteries from "../components/adminModule/PrivateLotteries/ViewPrivateLotteries";
import ViewPrivateLotteriesUsers from "../components/adminModule/PrivateLotteries/ViewPrivateLotteriesUsers";
import ViewPrivateLotteriesWishlist from "../components/adminModule/PrivateLotteries/ViewPrivateLotteriesWishlist";
import ViewPrivateLotteriesInvited from "../components/adminModule/PrivateLotteries/ViewPrivateLotteriesInvited";
import ViewPrivateLotteriesWinners from "../components/adminModule/PrivateLotteries/ViewPrivateLotteriesWinners";
import EditLottery from "../components/adminPanel/EditLottery";
import AdminPanelAgents from "../components/adminPanel/AdminPanelAgents";
import AgentsList from "../components/adminModule/Agents/AgentsList";
import AgentsRequestList from "../components/adminModule/Agents/AgentsRequestList";
import AgentsContainer from "../components/adminModule/Agents/AgentsContainer";
import CreateAgent from "../components/adminModule/Agents/CreateAgent";
import ViewAgentAll from "../components/adminModule/Agents/ViewAgentAll";
import ViewAgentRecharge from "../components/adminModule/Agents/ViewAgentRecharge";
import ViewAgentWithdrawal from "../components/adminModule/Agents/ViewAgentWithdrawal";
import ViewAgentPending from "../components/adminModule/Agents/ViewAgentPending";
import ViewAgent from "../components/adminModule/Agents/ViewAgent";
import AdminPanelPrivateAgents from "../components/adminPanel/AdminPanelPrivateAgents";
import PrivateAgentsList from "../components/adminModule/PrivateAgents/PrivateAgentsList";
import CreatePrivateAgent from "../components/adminModule/PrivateAgents/CreatePrivateAgent";
import EditPrivateAgent from "../components/adminModule/PrivateAgents/EditPrivateAgent";
import ViewPrivateAgent from "../components/adminModule/PrivateAgents/ViewPrivateAgent";
import ViewPrivateAgentInvited from "../components/adminModule/PrivateAgents/ViewPrivateAgentInvited";
import ViewPrivateAgentAll from "../components/adminModule/PrivateAgents/ViewPrivateAgentAll";
import ViewPrivateAgentRecharge from "../components/adminModule/PrivateAgents/ViewPrivateAgentRecharge";
import ViewPrivateAgentPending from "../components/adminModule/PrivateAgents/ViewPrivateAgentPending";
import ViewPrivateAgentWithdrawal from "../components/adminModule/PrivateAgents/ViewPrivateAgentWithdrawal";
import AdminPanelWithdrawals from "../components/adminPanel/AdminPanelWithdrawals";
import AdminPanelNumberGame from "../components/adminPanel/AdminPanelNumberGame";
import AdminPanelWheelGame from "../components/adminPanel/AdminPanelWheelGame";
import AdminPanelSubAdmin from "../components/adminPanel/AdminPanelSubAdmin";
import AdminPanelOffersBanners from "../components/adminPanel/AdminPanelOffersBanners";
import AdminPanelReports from "../components/adminPanel/AdminPanelReports";
import AdminPanelNotifications from "../components/adminPanel/AdminPanelNotifications";
import AdminPanelSettings from "../components/adminPanel/AdminPanelSettings";
import AdminPanelSupport from "../components/adminPanel/AdminPanelSupport";
import AdminPanelWallet from "../components/adminPanel/AdminPanelWallet";
import WalletList from "../components/adminModule/Wallet/WalletList";
import SendOtpForm from "../pages/SendOtpForm";
import VerifyOtpForm from "../pages/VerifyOtpForm";
import ResetPasswordForm from "../pages/ResetPasswordForm";
import Profile from "../components/adminModule/Profile/Profile";
import KYCPending from "../pages/KYCPending";
import Suspend from "../pages/Suspend";
import Test from "../Test";
import LotteryWinnerContainer from "../components/adminPanel/LotteryWinnerContainer";

const PrivateRoute = ({ component: Component }) => {
  const { currentUser, userRole, userDetails } = useAuth();

  // console.log("User Role"+userRole)
  // console.log("User Role"+currentUser)
  return (

    // <>
    //    {true && true ? (
    //       <Component />
    //    ) : (
    //       <Navigate to="/login" replace />
    //    )}
    // </>
    <>
      {/* {currentUser && userRole?.role?.agents ? (
            <Component />
         ) : (
            <Navigate to="/login" replace />
         )} */}

      {currentUser && userRole?.role?.agents ? (
        userDetails?.status === "ACTIVE" ? (
          <Component />
        ) : userDetails?.status === "KYC_PENDING" ? (
          <Navigate to="/kyc-pending" replace />
        ) : (
          userDetails?.status === "SUSPENDED" ? <Navigate to="/suspended" replace /> : ""
        )
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

const PublicRoute = ({ component: Component }) => {
  const { currentUser, userRole, userDetails } = useAuth();

  console.log("Current" + currentUser, userRole)
  return (

    <>
      {/* {currentUser && userRole?.role?.agents ? (
            <Navigate to="/lottery-agent" replace />
         ) : (
            <Component />
         )} */}
      {currentUser && userRole?.role?.agents && userDetails?.status === "ACTIVE" ? (
        <Navigate to="/lottery-agent" replace />
      ) : (
        <Component />
      )}
    </>
  );
};



export const router = createBrowserRouter([
  {
    path: "/lottery-agent",
    element: <PrivateRoute component={AdminLayout} />,
    children: [
      {
        path: "",
        element: <PrivateRoute component={Dashboard} />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "lottery",
        element: <PrivateRoute component={AdminPanelLottery} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={LotteryList} />,
          },
          {
            path: "create",
            element: <PrivateRoute component={CreateLottery} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={EditLottery} />,
          },
          {
            path: "view/:id",
            element: <PrivateRoute component={LotteryView} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={LotteryViewUsers} />
              },
              {
                path: "wishlist",
                element: <PrivateRoute component={LotteryViewWishlist} />
              },
              {
                path: "winners",
                element: <PrivateRoute component={LotteryWinnerContainer} />
              },
            ]
          },
        ]
      },
      {
        path: "agents",
        element: <PrivateRoute component={AdminPanelAgents} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={AgentsContainer} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={AgentsList} />,
              },
              {
                path: "request",
                element: <PrivateRoute component={AgentsRequestList} />,
              },
            ]
          },
          {
            path: "create",
            element: <PrivateRoute component={CreateAgent} />,
          },
          {
            path: "view/:id",
            element: <PrivateRoute component={ViewAgent} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={ViewAgentAll} />,
              },
              {
                path: "recharge",
                element: <PrivateRoute component={ViewAgentRecharge} />,
              },
              {
                path: "withdrawal",
                element: <PrivateRoute component={ViewAgentWithdrawal} />,
              },
              {
                path: "pending",
                element: <PrivateRoute component={ViewAgentPending} />,
              },
            ]
          },
        ]
      },
      {
        path: "private-agents",
        element: <PrivateRoute component={AdminPanelPrivateAgents} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={PrivateAgentsList} />,
          },
          {
            path: "create",
            element: <PrivateRoute component={CreatePrivateAgent} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={EditPrivateAgent} />,
          },
          {
            path: "view/:id",
            element: <PrivateRoute component={ViewPrivateAgent} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={ViewPrivateAgentAll} />,
              },
              {
                path: "recharge",
                element: <PrivateRoute component={ViewPrivateAgentRecharge} />,
              },
              {
                path: "withdrawal",
                element: <PrivateRoute component={ViewPrivateAgentWithdrawal} />,
              },
              {
                path: "pending",
                element: <PrivateRoute component={ViewPrivateAgentPending} />,
              },
              {
                path: "invited",
                element: <PrivateRoute component={ViewPrivateAgentInvited} />,
              },
            ]
          },
        ]
      },
      {
        path: "private-lotteries",
        element: <PrivateRoute component={AdminPanelPrivateLotteries} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={PrivateLotteriesList} />,
          },
          {
            path: "create",
            element: <PrivateRoute component={CreatePrivateLotteries} />,
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={EditPrivateLotteries} />,
          },
          {
            path: "view/:id",
            element: <PrivateRoute component={ViewPrivateLotteries} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={ViewPrivateLotteriesUsers} />
              },
              {
                path: "wishlist",
                element: <PrivateRoute component={ViewPrivateLotteriesWishlist} />
              },
              {
                path: "invited",
                element: <PrivateRoute component={ViewPrivateLotteriesInvited} />
              },
              {
                path: "winners",
                element: <PrivateRoute component={ViewPrivateLotteriesWinners} />
              },
            ]
          },
        ]
      },
      {
        path: "users",
        element: <PrivateRoute component={AdminPanelUsers} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={UsersList} />,
          },
          {
            path: "view/:id",
            element: <PrivateRoute component={UserView} />,
            children: [
              {
                path: "",
                element: <PrivateRoute component={UserViewTranscations} />,
              },
              {
                path: "winnings",
                element: <PrivateRoute component={UserViewWinnings} />,
              },
            ]
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={UserEdit} />,
          },
        ]
      },
      {
        path: "wallet",
        element: <PrivateRoute component={AdminPanelWallet} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={WalletList} />,
          },
        ]
      },
      {
        path: "withdrawals",
        element: <PrivateRoute component={AdminPanelWithdrawals} />,
      },
      {
        path: "number-game",
        element: <PrivateRoute component={AdminPanelNumberGame} />,
      },
      {
        path: "wheel-game",
        element: <PrivateRoute component={AdminPanelWheelGame} />,
      },
      {
        path: "sub-admin",
        element: <PrivateRoute component={AdminPanelSubAdmin} />,
      },
      {
        path: "offers-banners",
        element: <PrivateRoute component={AdminPanelOffersBanners} />,
      },
      {
        path: "reports",
        element: <PrivateRoute component={AdminPanelReports} />,
      },
      {
        path: "notifications",
        element: <PrivateRoute component={AdminPanelNotifications} />,
      },
      {
        path: "settings",
        element: <PrivateRoute component={AdminPanelSettings} />,
      },
      {
        path: "support",
        element: <PrivateRoute component={AdminPanelSupport} />,
      }
    ]
  },
  {
    path: "/login",
    element: <PublicRoute component={AdminLogin} />,
  },
  // {
  //    path: "/login",
  //    element: <PublicRoute component={KYCPending} />,
  // },
  {
    path: "/kyc-pending",
    element: <PublicRoute component={KYCPending} />,
  },
  {
    path: "/suspended",
    element: <PublicRoute component={Suspend} />,
  },
  // {
  //    path: "/forgotPassword",
  //    element: <PublicRoute component={ForgotPassword} />,
  // },
  // {
  //    path: "/changePassword",
  //    element: <PublicRoute component={ChangePassword} />,
  // },
  // {
  //    path: "/otp-verification",
  //    element: <PublicRoute component={OTPVerfication} />,
  // },
  {
    path: "/forgot-password",
    element: <PublicRoute component={SendOtpForm} />,
  },
  {
    path: "/verify-otp",
    element: <PublicRoute component={VerifyOtpForm} />,
  },
  {
    path: "/reset-password",
    element: <PublicRoute component={ResetPasswordForm} />,
  },
  {
    path: "/success",
    element: <Success />,
  },
  {
    path: "/failure",
    element: <Failure />,
  },
  {
    path: "/",
    element: <Welcome />,
  },
   {
      path: "/test",
      element: <Test />,
   },
])