import React, { useContext, useState, useEffect } from "react"
import { auth } from "../Firebase";
import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { APIurls } from "../api/apiConstant";
// import { toast } from "react-toastify";


const AuthContext = React.createContext()

export const useAuth = () => {
   return useContext(AuthContext)
}

export const AuthContextProvider = ({ children }) => {
   const [currentUser, setCurrentUser] = useState()
   const [loading, setLoading] = useState(true);
   const [userRole, setUserRole] = useState();
   const [userDetails, setUserDetails] = useState();

   function login(email, password) {
      return signInWithEmailAndPassword(auth, email, password)
   }

   function logout() {
      return signOut(auth)
   }

   function resetPassword(email) {
      return sendPasswordResetEmail(auth, email);
   }

   function updateEmail(email) {
      return currentUser.updateEmail(email)
   }

   function updatePassword(password) {
      return currentUser.updatePassword(password)
   }

   function getAccessToken() {
      return currentUser?.getIdToken(true)
   }
   function saveUserRole(value) {
      setUserRole(value)
   }
   const getUserRoleFunc = async (user) => {
      try {
         console.log(user?.accessToken)
         const result = await fetch(APIurls.getRoles, {
            method: "GET",
            headers: {
               Authorization: `Bearer ${user.accessToken}`,
            }
         });
         const resultJson = await result.json();

         console.log(resultJson)
         if (!result.ok) {
            throw new Error("Failed to login");
         }

         setUserRole(resultJson?.response)

      } catch (error) {
         console.log(error);
      }
   }

   async function fetchAgentDetails(token) {
    

       console.log("TOKEN",token)
      try {
         const response = await fetch(APIurls.fetchAgentDetails, {
            method: "GET",
            headers: {
               Authorization: `Bearer ${token}`,
            }
         });
         if(!response.ok){
            throw new Error("Failed to fetch agent details")
         }
         const result = await response.json();
         setUserDetails(result?.response?.AgentData);
         console.log("AGENT DETAILS", result?.response?.AgentData);
      } catch (error) {
         console.warn("Failed to fetch agent details");
         // toast.error("Failed to fetch agent details");
      }
   }

   useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {

         const token = await getAccessToken();
         setCurrentUser(user)
         await getUserRoleFunc(user);
         // todo: fetch agent details.
         await fetchAgentDetails(token);
         setLoading(false)
      })
      console.log(unsubscribe);

      return unsubscribe
   }, [currentUser])

   const value = {
      currentUser,
      login,
      logout,
      resetPassword,
      updateEmail,
      updatePassword,
      getAccessToken,
      saveUserRole,
      userRole,
      userDetails
   }

   return (
      <AuthContext.Provider value={value}>
         {!loading && children}
      </AuthContext.Provider>
   )
}