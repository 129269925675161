import React from 'react'
import { useOutletContext } from 'react-router-dom'
import LotteryViewWinner from './LotteryViewWinner';
import LotteryViewAnnounceWinner from './LotteryViewAnnounceWinner';

export default function LotteryWinnerContainer() {
  const [mongoId, lotteryData, setIsRefetch] = useOutletContext();

  return (
    <div>
      {
        lotteryData?.winnerAnnounceStatus ? (
          <LotteryViewWinner data={lotteryData} />
        ) : (
          <LotteryViewAnnounceWinner mongoId={mongoId} setIsRefetch={setIsRefetch} />
        )
      }
    </div>
  )
}
