import { Icon } from "@iconify/react/dist/iconify.js";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";

const BankInformation = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [errors, setErrors] = useState([]);
  console.log("All Bank Details =========> ", bankAccounts);


  const addBankAccount = () => {
    const newAccount = {
      id: bankAccounts.length + 1,
      accountNumber: "",
      ifscCode: "",
      name: "",
      branch: "",
    };
    setBankAccounts([...bankAccounts, newAccount]);
    setErrors([...errors, {}]); // Add empty errors object for new account
  };

  const deleteBankAccount = (id) => {
     //Delete operation
     const updatedAccounts = bankAccounts.filter((account) => account.id !== id);
     setBankAccounts(updatedAccounts);
     const updatedErrors = errors.filter((error, index) => index !== id - 1); // Adjust errors array accordingly
     setErrors(updatedErrors);
  };

  const handleInputChange = (id, field, value) => {
    const updatedAccounts = bankAccounts.map((account, index) => {
      if (account.id === id) {
        return { ...account, [field]: value };
      }
      return account;
    });
    setBankAccounts(updatedAccounts);

    // Validate input and update errors state
    const validationErrors = {
      ...errors[id - 1],
      [field]: validateField(field, value),
    };
    const updatedErrors = [...errors];
    updatedErrors[id - 1] = validationErrors;
    setErrors(updatedErrors);
  };

  const validateField = (field, value) => {
    switch (field) {
      case "accountNumber":
        // Example: Validate accountNumber as a number
        return isNaN(value) ? "Account Number should be a valid number." : "";
      case "ifscCode":
        // Example: Validate IFSC Code as alphanumeric
        const alphanumericRegex = /^[A-Za-z0-9]+$/;
        return !alphanumericRegex.test(value)
          ? "IFSC Code should contain only alphanumeric characters."
          : "";
      case "name":
        // Example: Validate name (optional)
        return value.trim().length === 0 ? "Name is required." : "";
      case "branch":
        // Example: Validate branch (optional)
        return value.trim().length === 0 ? "Branch is required." : "";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if all input fields are valid before submission
    const allValid = bankAccounts.every((account, index) => {
      const accountErrors = errors[index];
      return (
        Object.values(accountErrors).every((error) => error === "") &&
        account.accountNumber !== "" &&
        account.ifscCode !== "" &&
        account.name !== "" &&
        account.branch !== ""
      );
    });

    if (allValid) {
      // Submit logic goes here
      console.log("Bank Accounts:", bankAccounts);
      alert("Bank account data submitted successfully!");
      // Optionally reset bankAccounts state after submission
      // setBankAccounts([]);
    } else {
      alert("Please fill in all required fields correctly.");
    }
  };

  return (
    <div>
      <div className="flex md:flex-row flex-col md:justify-between md:items-center">
        <h1 className="text-[1.25rem] font-medium">Bank Information</h1>
        <button
          onClick={addBankAccount}
          className="bg-[#25BF17] text-white font-medium px-6 py-3 flex items-center md:gap-3 gap-1 rounded-md"
        >
          <Icon className="text-[1.2rem]" icon="tabler:plus" />
          <span>Add New Bank Account</span>
        </button>
      </div>

      {bankAccounts.map((account, index) => (
        <div key={account.id} className="border-[1px] border-gray-200 rounded-md p-5 mt-5 shadow-2xl md:mx-[3rem]">
          <div className="flex justify-between items-center">
          <h2 className="text-[1.25rem] font-medium">
                Bank -{account.id}
              </h2>

              <div className="flex justify-end">
                <button
                  className="border-[1px] border-red-500 text-red-500  hover:bg-red-700 hover:text-white font-bold p-2 rounded"
                  onClick={() => deleteBankAccount(account.id)}
                >
                  <Icon className="text-2xl" icon="material-symbols:delete" />
                </button>
              </div>
          </div>
          <form className="md:mx-[3rem] mt-2">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-x-16 gap-y-5">
              <div>
                <label
                  className="block text-[14px] w-full text-[#5E6366] mb-1"
                  htmlFor={`account-number-${account.id}`}
                >
                  Account Number
                </label>
                <input
                  className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                  id={`account-number-${account.id}`}
                  type="text"
                  value={account.accountNumber}
                  onChange={(e) =>
                    handleInputChange(
                      account.id,
                      "accountNumber",
                      e.target.value
                    )
                  }
                  placeholder="Enter Account Number"
                  required
                />
                {errors[index]?.accountNumber && (
                  <span className="text-red-500 text-sm">
                    {errors[index].accountNumber}
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block text-[14px] w-full text-[#5E6366] mb-1"
                  htmlFor={`ifsc-code-${account.id}`}
                >
                  IFSC Code
                </label>
                <input
                  className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                  id={`ifsc-code-${account.id}`}
                  type="text"
                  value={account.ifscCode}
                  onChange={(e) =>
                    handleInputChange(account.id, "ifscCode", e.target.value)
                  }
                  placeholder="Enter IFSC Code"
                  required
                />
                {errors[index]?.ifscCode && (
                  <span className="text-red-500 text-sm">
                    {errors[index].ifscCode}
                  </span>
                )}
                <div className="flex items-center gap-3 mt-1">
                  <span className="text-[14px]">STATE BANK OF INDIA</span>
                  <Icon
                    className="text-[#24A305] text-[1.5rem]"
                    icon="ri:checkbox-circle-fill"
                  />
                </div>
              </div>

              <div>
                <label
                  className="block text-[14px] w-full text-[#5E6366] mb-1"
                  htmlFor={`name-${account.id}`}
                >
                  Name
                </label>
                <input
                  className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                  id={`name-${account.id}`}
                  type="text"
                  value={account.name}
                  onChange={(e) =>
                    handleInputChange(account.id, "name", e.target.value)
                  }
                  placeholder="Enter Bank Verified Name"
                  required
                />
                {errors[index]?.name && (
                  <span className="text-red-500 text-sm">
                    {errors[index].name}
                  </span>
                )}
                <div className="flex items-center gap-3 mt-1">
                  <span className="text-[14px]">Bank Verified Name</span>
                  <Icon
                    className="text-[#24A305] text-[1.5rem]"
                    icon="ri:checkbox-circle-fill"
                  />
                </div>
              </div>

              <div>
                <label
                  className="block text-[14px] w-full text-[#5E6366] mb-1"
                  htmlFor={`branch-${account.id}`}
                >
                  Branch
                </label>
                <input
                  className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                  id={`branch-${account.id}`}
                  type="text"
                  value={account.branch}
                  onChange={(e) =>
                    handleInputChange(account.id, "branch", e.target.value)
                  }
                  placeholder="Enter Branch Name"
                  required
                />
                {errors[index]?.branch && (
                  <span className="text-red-500 text-sm">
                    {errors[index].branch}
                  </span>
                )}
              </div>
            </div>
          </form>
        </div>
      ))}

      {bankAccounts.length > 0 && (
        <div className="mt-5 flex justify-end">
          <button
            className="bg-[#25BF17] text-white font-medium px-6 py-3 flex items-center gap-3 rounded-md"
            onClick={handleSubmit}
          >
            <span>Update Bank Informations</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default BankInformation;
