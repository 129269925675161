import React, { useState } from "react";
import { Icon } from "@iconify/react";
import adminAvatar from '../../assets/adminAvatar.svg'
import Avatar from 'react-avatar';
import { useAuth } from "../../store/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import strikeLogo from "../../assets/strike-logo.svg";
import NotificationModal from "../../helpers/NotificationModal";
import { toggleNotificationModal } from "../../store/notificationSlice";
import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";



const Header = ({ isAdminSidebarOpen, setIsAdminSidebarOpen }) => {
  //   const { userId, userName } = useSelector((state) => state.employee);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { showNotificationModal } = useSelector((store) => store.notification);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const { userDetails, logout } = useAuth();

  const handleLogout = ()=>{
    const isLoggedOut = window.confirm('Are you sure ?');
    if(isLoggedOut){
      logout()
    }
    setIsDropdownVisible(false);
  }
  const handleProfileNavigate = ()=>{
    navigate("profile")
    setIsDropdownVisible(false);
  }

  return (
    <div className="h-[4.5rem] bg-white hidden sm:flex flex-row  justify-between items-center px-[1rem] md:p-[2rem] lg:px-[3rem] py-[1rem] border-b border-[#DFDFDF] shadow-md fixed w-full z-10 left-0">
      {/* Icon  */}
      <div className="flex flex-row items-center gap-[2rem] md:gap-[4rem]">
        <div
          className="px-[0.3rem] rounded-md lg:hidden text-white bg-primary cursor-pointer"
          onClick={() => {
            setIsAdminSidebarOpen(true);
          }}
        >
          <Icon icon="ic:outline-menu" className="text-[2.5rem] text-white " />
        </div>
        <div className="hidden lg:flex">
          <Link to="/lottery-agent">
            <img src={strikeLogo} alt="" className="w-[80%]" />
          </Link>
        </div>
        <div
          className="flex flex-row items-center border rounded-md border-[#DFDFDF] bg-[#F6F6F8] 
          px-[1rem] py-[0.5rem] gap-[1rem]"
          style={{ boxShadow: "4px 4px 150px rgba(0, 0, 0, 0.13)" }}
        >
          <div className="text-sm cursor-pointer text-white bg-primary p-[0.2rem] rounded-[4px]">
            <Icon icon="akar-icons:search" className="" />
          </div>
          <input
            type="text"
            className="w-[15rem] placeholder-[#848484] text-black text-[0.9rem] outline-none bg-[#F6F6F8]"
            placeholder="Search...."
          />
        </div>
      </div>

      <div className="flex flex-row items-center gap-[1rem] sm:gap-[2rem] md:gap-[3rem]">
      <div className="relative">
          <button
            onClick={() => dispatch(toggleNotificationModal())}
            className="bg-[#F6F6F8] p-[0.5rem] rounded-full cursor-pointer"
          >
            <Icon
              icon="mi:notification"
              className="text-[1.5rem] text-[#4D4D4D]"
            />
          </button>
          {showNotificationModal && (
            <div className="absolute top-[3.5rem] right-[-5rem] shadow-lg z-40 w-[25rem] bg-white">
              <NotificationModal />
            </div>
          )}
        </div>

        {/* Profile Info  */}
        <div className="relative">
        <div onClick={()=>setIsDropdownVisible(!isDropdownVisible)} className="flex flex-row items-center gap-[1rem] cursor-pointer select-none">
        <Avatar name={userDetails?.FirstName + " " + userDetails?.LastName  || "NA"} size="40" round={true}/>
          <div>
            <h3 className="text-[#4D4D4D] text-[0.8rem] font-[600]">
              {userDetails?.FirstName || "NA"}
            </h3>
            <p className="text-[#4D4D4D] text-[0.7rem] font-[500]">Admin</p>
          </div>
          {
            isDropdownVisible ? <Icon className="text-2xl" icon="iconamoon:arrow-up-2-light" /> : <Icon className="text-2xl" icon="iconamoon:arrow-down-2-light" />
          }
        </div>

        {/* Dropdown  */}
        {
          isDropdownVisible && (<ul className="absolute mt-4 shadow-md bg-white border-[1px] border-gray-300 py-3 min-w-[140px] space-y-3">
            <li onClick={handleProfileNavigate} className="hover:bg-gray-300 cursor-pointer p-2 flex items-center gap-2">
            <Icon className="text-[20px]" icon="clarity:user-line" />
              <span>Profile</span>
            </li>
            <li onClick={handleLogout} className="hover:bg-gray-300 cursor-pointer p-2 flex items-center gap-2 text-red-500">
            <Icon icon="solar:logout-2-bold" className="text-[1.5rem]" />
              <span>Log Out</span>
            </li>
          </ul>)
        }

        </div>
      </div>
    </div>
  );
};

export default Header;
