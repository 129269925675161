import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_red.css";
import StepperController from "../../../helpers/StepperController";
import { useDispatch, useSelector } from "react-redux";
import { updatePersonalDetails } from "../../../store/createAgentSlice";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import "react-phone-number-input/style.css";
import { formatPhoneNumberIntl } from "react-phone-number-input";


export default function PersonalDetails({ currentStep, handleClick }) {
  const [selectImg, setSelectImg] = useState();
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    firstName, lastName, email, mobileNumber, whatsAppNumber, telegramNumber, nationality, passportNumber, country, state, city } = useSelector((store) => store.createAgent.personalDetails);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if (!(firstName && lastName && email && isEmailValid && mobileNumber && isMobileNumberValid && passportNumber && nationality)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [firstName, lastName, email, isEmailValid, mobileNumber, isMobileNumberValid, passportNumber, nationality]);

  return (
    <div className="flex flex-col gap-[1rem] px-0 sm:px-[1rem] py-[1.5rem] font-[500]">
      <div className="text-primary flex items-center gap-[1rem] px-[1rem]">
        <Icon icon="heroicons-solid:user-group" className="text-[2rem]" />
        <h1 className="text-[1.2rem] sm:text-[1.7rem] font-semibold">
          Create Agent
        </h1>
      </div>
      <form
        className="bg-white flex flex-col gap-[2rem] px-[2rem] py-[1rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="firstName" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              First Name
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter First Name"
              value={firstName}
              onChange={(e) => {
                if (!(/[\d]/.test(e.target.value))) {
                  dispatch(updatePersonalDetails({ firstName: e.target.value }));
                }
              }}
            />
            {showError && !firstName && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="lastName" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Last Name
            </label>

            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Last Name"
              value={lastName}
              onChange={(e) => {
                if (!(/[\d]/.test(e.target.value))) {
                  dispatch(updatePersonalDetails({ lastName: e.target.value }));
                }
              }}
            />
            {showError && !lastName && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="emailId" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Email Id
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => {
                if (emailRegex.test(e.target.value)) {
                  setIsEmailValid(true)
                }
                else {
                  setIsEmailValid(false)
                }
                dispatch(updatePersonalDetails({ email: e.target.value }));
              }}
            />
            {((showError && !email) || !isEmailValid) && <p className="text-[#E92215] text-sm">{(!isEmailValid && showError) ? "Invalid email" : showError ? "*This field is required." : ""}</p>}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="pricePerTicket" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Mobile Number
            </label>
            <PhoneInput className='text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none'
              initialValueFormat="national"
              type="text"
              defaultCountry="IN"
              style={{
                border: "0.9px solid rgba(118, 125, 134, 0.40)",
                borderRadius: "5px",
              }}
              placeholder='Enter Your Mobile Number'
              value={formatPhoneNumberIntl(mobileNumber + '')}
              onChange={(value) => {
                if (isValidPhoneNumber(value + '')) {
                  dispatch(updatePersonalDetails({ mobileNumber: value }))
                  setIsMobileNumberValid(true)
                } else {
                  setIsMobileNumberValid(false);
                }
              }}
            />
            {((showError && !mobileNumber) || !isMobileNumberValid) && <p className="text-[#E92215] text-sm">{(!isMobileNumberValid && showError) ? "Invalid mobile number" : showError ? "*This field is required." : ""}</p>}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="expectedDateOfDraw" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              WhatsApp Number
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter WhatsApp Number"
              value={whatsAppNumber}
              onChange={(e) => dispatch(updatePersonalDetails({
                whatsAppNumber: e.target.value,
              }))}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Telegram Number
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter Telegram Number"
              value={telegramNumber}
              onChange={(e) => dispatch(updatePersonalDetails({
                telegramNumber: e.target.value,
              }))}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="totalWinningAmount" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Nationality
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Nationality"
              value={nationality}
              onChange={(e) => dispatch(updatePersonalDetails({
                nationality: e.target.value,
              }))}
            />
            {showError && !nationality && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="numOfWinners" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Passport Number
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Passport Number"
              value={passportNumber}
              onChange={(e) => dispatch(updatePersonalDetails({
                passportNumber: e.target.value,
              }))}
            />
            {showError && !passportNumber && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Country
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Country"
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              State
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter State"
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              City
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter City"
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Pincode
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter Pincode"
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="profilePicture" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Profile Picture
            </label>
            <div className="flex flex-col items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
              {selectImg?.name ? (
                <>
                  <h2 className="text-[1.1rem] font-semibold text-green-600 my-[1rem]">
                    Uploaded document
                  </h2>
                  <div className="flex gap-[1rem] items-center">
                    <h2 className="text-[1.1rem] text-black font-[500]">
                      {selectImg?.name.length > 30
                        ? selectImg.name.substring(0, 30) + "..."
                        : selectImg.name}
                    </h2>
                    <Icon
                      onClick={() => setSelectImg(null)}
                      icon="akar-icons:cross"
                      className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                    />
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                  >
                    <Icon
                      icon="material-symbols:cloud-upload"
                      className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                    />
                  </button>
                  <input
                    type="file"
                    // accept="image/*"
                    value=""
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      if (files[0]) {
                        setSelectImg(files[0]);
                      }
                    }}
                  />
                  <p className="text-[0.9rem] text-[#D9D9D9]">
                    Upload jpg, png, pdf. Size up to 20MB
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <StepperController currentStep={currentStep} error={error} setShowError={setShowError} handleClick={handleClick} />
        </div>
      </form>
    </div>
  )
}
