import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import profilePhoto from "../../../assets/profile-photo.png";
import BankInformation from "./BankInformation";
import UPIForm from "./UPIForm";
import { useAuth } from "../../../store/AuthContext";
// billalHossain76@
export default function Profile() {
  const { userDetails } = useAuth();
  const {
    FirstName,
    LastName,
    email,
    mobileNumber,
    location,
    createdAt,
    agentId,
  } = userDetails || {};
  const handleEditProfilePhoto = (e) => {
    e.preventDefault();
  };
  return (
    <div className="bg-white space-y-20">
      {/* Personal Informations  */}
      <div>
        <h1 className="text-[1.25rem] font-medium mb-[2rem]">
          Personal Information
        </h1>
        <form className="md:mx-[3rem] border-[1px] border-gray-200 rounded-md p-5 shadow-2xl">
          <div className="flex md:flex-row flex-col md:items-center gap-[3rem]">
            <img
              className="md:w-[200px] w-full h-[200px]"
              src={profilePhoto}
              alt="Profile"
            />
            <div className="space-y-6">
              <h3 className="text-[1.25rem] font-bold text-[#25BF17]">
                Agent ID (123456)
              </h3>
              <button
                onClick={handleEditProfilePhoto}
                className="bg-[#25BF17] md:w-auto w-full text-white font-medium md:px-6 px-3 py-3 flex items-center gap-3 rounded-md"
              >
                <Icon className="text-[1.5rem]" icon="ic:round-edit" />
                <span>Edit Profile Picture</span>
              </button>
            </div>
          </div>

          {/* Input Fields  */}
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-16 gap-y-8 mt-[2rem]">
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="full-name"
              >
                Full Name
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="full-name"
                type="text"
                placeholder="Enter Full Name"
                value={`${FirstName} ${LastName}`}
              />
            </div>

            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="mobile-number"
              >
                Mobile Number
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="mobile-number"
                type="text"
                placeholder="Enter Mobile Number"
                value={mobileNumber}
              />
            </div>

            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="email-address"
              >
                Email Address
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="email-address"
                type="email"
                placeholder="Enter Email Address"
                value={email}
              />
            </div>
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="date"
              >
                Date of Onboarding
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="date"
                type="text"
                placeholder="Enter Date"
                value={createdAt?.split("T")[0]}
              />
            </div>
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="business-name"
              >
                Business Name
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="business-name"
                type="text"
                placeholder="Enter Business Name"
                value={agentId}
              />
            </div>
            <div>
              <label
                className="block text-[14px] w-full text-[#5E6366] mb-1"
                for="geo-location"
              >
                Geo Location
              </label>
              <input
                className="outline-none border-[1px] w-full border-[#D9D9D9] rounded-md py-2 px-4"
                id="geo-location"
                type="text"
                placeholder="Enter Geo Location"
                value={location?.city}
              />
            </div>
          </div>
        </form>
      </div>
      <BankInformation />

      {/* UPI Form End  */}
      <UPIForm />
    </div>
  );
}
