import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/headers/Header";
import { motion, AnimatePresence } from "framer-motion"
import AdminSideBar from "../components/sidebars/AdminSideBar";
import MobileHeader from "../components/headers/MobileHeader";


const AdminLayout = () => {
  const [isAdminSidebarOpen, setIsAdminSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    document.getElementById('scrollable-div').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname])

  return (
    <div>
      {/* <div className="lg:hidden z-40 sticky top-0">
        {isAdminSidebarOpen ? (
          <div>
            <AdminSidebar
              isAdminSidebarOpen={isAdminSidebarOpen}
              setIsAdminSidebarOpen={setIsAdminSidebarOpen}
            />
          </div>
        ) : (
          <div className="bg-[#E5EDFF] w-full text-black">
            <FaBars
              onClick={() => setIsAdminSidebarOpen(true)}
              className="p-5 text-6xl "
            />
          </div>
        )}
      </div> */}

      <div className="flex overflow-y-hidden bg-white">
        <div className="hidden lg:inline-block">
          <AdminSideBar
            isAdminSidebarOpen={isAdminSidebarOpen}
            setIsAdminSidebarOpen={setIsAdminSidebarOpen}
          />
        </div>
        <AnimatePresence mode='wait'>

          {isAdminSidebarOpen && <motion.div className="absolute top-0 left-0 z-40 lg:hidden flex-1 "
            initial={{ x: -100 }} animate={{ x: 0 }} transition={{ type: "linear", duration: 0.5, }} exit={{ x: -400, transition: "linear", duration: 1.5, }} >

            <AdminSideBar
              isAdminSidebarOpen={isAdminSidebarOpen}
              setIsAdminSidebarOpen={setIsAdminSidebarOpen}
            />
          </motion.div>}
        </AnimatePresence>



        <div id="scrollable-div" className="lg:flex-[4] w-full h-screen overflow-y-auto overflow-x-hidden px-[1rem]">
          {/* <div
            className={`h-full  m-0  lg:my-0 lg:mx-[0.2rem] overflow-x-hidden relative flex flex-col `}
          > */}
            <Header isAdminSidebarOpen={isAdminSidebarOpen}
              setIsAdminSidebarOpen={setIsAdminSidebarOpen} />
            <MobileHeader isAdminSidebarOpen={isAdminSidebarOpen}
              setIsAdminSidebarOpen={setIsAdminSidebarOpen} />
            <div className="md:mt-[6rem] mt-[1rem]">
              <Outlet />
            </div>
            {/* <div className="sticky top-[15rem] left-0  z-50 hidden md:flex  ">
              <div className="flex flex-row justify-start ">
                <Icon
                  icon="ic:round-keyboard-arrow-left"
                  width={25}
                  className="bg-black rounded-full text-white"
                  onClick={()=>setIsAdminSidebarOpen(false)}
                />
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>

    </div>
  );
};

export default AdminLayout;

