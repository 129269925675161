import React from 'react'
import { Icon } from '@iconify/react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import userImg from '../../assets/user-img.png'
import { useQuery } from 'react-query';
import { APIurls } from "../../api/apiConstant";
import { useAuth } from "../../store/AuthContext";
import moment from 'moment';
import { animateScroll as scroll } from "react-scroll";
import { RotatingLines } from 'react-loader-spinner';
import Userprofile from "../../assets/profile.png"

export default function UserView() {

  const { id } = useParams();

  const navigate = useNavigate();

  const { getAccessToken } = useAuth()

  function formatToIndianCurrency(number) {
      return number?.toLocaleString('en-IN', {
          // style: 'currency',
          currency: 'INR'
      });
  }

  const fetchUserDataById = async () => {

      const token = await getAccessToken()

      /// console.log("Lottary",token)
      const response = await fetch(`${APIurls.fetchUserById}/${id}`, {
          method: "GET",
          headers: {
              Authorization: `Bearer ${token}`,
          }
      });
      if (!response.ok) {
          throw new Error("Failed to Fetch User Data");
      }
      const result = await response.json();
      return result?.response?.UserData;
  };

  const { data: userData, error, isLoading } = useQuery('userDatas', fetchUserDataById);

  console.log(userData)

  if (isLoading) {
    return (
        <div className='flex justify-center items-center h-screen'>
            <RotatingLines
                strokeColor="green"
                strokeWidth="5"
                animationDuration="0.75"
                width="200"
                visible={true}
            />
        </div>
    );
}

  return (
    <div className='flex flex-col gap-[2rem] my-[1rem] px-[1rem]'>
  
      <div className='flex justify-between'>
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
        >
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
          <span>Back</span>
        </button>
        <button
          onClick={() => navigate(-1)}
          className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-[#FF0023] py-[0.4rem] px-[1rem] rounded-md"
        >
          Block User
        </button>
      </div>
      <div className='h-[155px] flex gap-[1rem]'>
        <div>
          <img src={userData?.imageUrl || Userprofile} alt="userImg" className='rounded-xl h-[155px] w-[170px] bg-cover bg-center' />
        </div>
        <div className='flex flex-col justify-between gap-[1rem] p-[1rem] rounded-xl'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className='flex gap-[2rem] items-center'>
            <div className='bg-[#D1F0CF] p-[0.1rem] rounded-md'>
              <Icon icon="mdi:user" className='text-primary text-[2rem]' />
            </div>
            <div>
              <h1 className='font-bold whitespace-nowrap'>{(userData?.FirstName +" "+ userData?.LastName)}</h1>
              <p className='text-[#8B8D97] text-[0.8rem] whitespace-nowrap'>Created on <span className='font-bold'>{moment(userData?.createdAt).format("D MMMM YYYY")}</span></p>
            </div>
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[7.5rem] py-[0.3rem] ${"ACTIVE" === "PENDING" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#25BF17] bg-[#D1F0CF]"}`}>
              <span className={`${"ACTIVE" === "PENDING" ? "bg-[#FF0023]" : "bg-[#25BF17]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
              <h2>ACTIVE</h2>
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div>
              <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>Email Id</label>
              <h2 className='font-semibold'>{userData?.email}</h2>
            </div>
            <div>
              <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem] whitespace-nowrap'>Mobile Number</label>
              <h2 className='font-semibold'>{userData?.mobileNumber}</h2>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap justify-between gap-[1.5rem] p-[1rem] rounded-xl'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div>
            <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>Nationality</label>
            <div className='flex gap-[0.5rem] items-center'>
              <Icon icon="twemoji:flag-india" className='text-[2rem]' />
              <h2 className='font-semibold'>{userData?.location?.country}</h2>
            </div>
          </div>
          <div>
            <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>Date of Birth</label>
            <h2 className='font-semibold'>{userData?.dob}</h2>
          </div>
          <div>
            <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>Gender</label>
            <h2 className='font-semibold'>{userData?.Gender}</h2>
          </div><br></br>
          <div>
            <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>Address</label>
            <h2 className='font-semibold'>{userData?.location?.address} , {userData?.location?.state} , {userData?.location?.city} - {userData?.location?.pincode}</h2>
          </div>
        </div>
      </div>
      <div>
        <div className='flex gap-[2rem] text-[1.2rem] border-b-2 border-[#989898]'>
          <NavLink to={`/admin/users/view/${id}`} end>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Transactions
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/users/view/${id}/winnings`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Winnings
              </h2>
            )}
          </NavLink>
        </div>
        <Outlet />
      </div>
    </div>
  )
}
