import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import winnerLogo from '../../assets/lottery-winner-logo.svg'
import { useAuth } from '../../store/AuthContext';
import { APIurls } from '../../api/apiConstant';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';

export default function LotteryViewWinnerAnnounce({ mongoId, setIsRefetch }) {
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { getAccessToken } = useAuth();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const announceWinnerHanlder = async () => {
    const token = await getAccessToken();
    setIsBtnDisabled(true);
    setLoaderBtn(true);
    try {
      const response = await fetch(APIurls.announceWinner, {
        method: "POST",
        body: JSON.stringify({
          ticketMongoID: mongoId,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      if (!response.ok) {
        if (response.status === 400) {
          const errorData = await response.json();
          console.log("Error Data:", errorData);
          toast.error(errorData.message);
          throw new Error(errorData.message);
        } else {
          toast.error("Failed to Announce Winner");
          throw new Error("Failed to Announce Winner");
        }
      }
      const result = await response.json();
      console.log("Announce Winner result", result);
      toast.success("Winner Announced Successfully!");
      setIsRefetch(true);
    } catch (error) {
      console.error(error.message);
    }
    setLoaderBtn(false);
    setIsBtnDisabled(false);
  }

  return (
    <div className="py-[1rem] w-auto"        >
      <div className='flex justify-between items-center'>
        <h1 className="text-[1.1rem] sm:text-[1.4rem] font-semibold">
          Winners
        </h1>
        <div className='flex items-center gap-[1rem]'>
          <button
            // onClick={() => navigate(-1)}
            className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
          >
            <Icon icon="ic:round-notifications-active" className='text-[1.3rem]' />
            Send Notifications
          </button>
          <button
            // onClick={() => navigate(-1)}
            className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
          >
            <Icon icon="tabler:download" className='text-[1.3rem]' />
            Export
          </button>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center gap-[0.5rem]'>
        <div className='p-[1rem] bg-[#E0E0E0] w-auto rounded-full'>
          <img src={winnerLogo} alt="" />
        </div>
        <h1 className='text-[1.5rem] font-bold mt-[1rem]'>Winners</h1>
        <p className='text-[#858585]'>
          Yet to be Decided.
        </p>
        {
          loaderBtn ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#24B24B"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            <button onClick={announceWinnerHanlder}
              disabled={isBtnDisabled}
              className="text-[1rem] font-[500] text-white bg-primary py-[0.5rem] px-[1.5rem] rounded-md mt-4 mb-8"
            >
              Announce Winner
            </button>
          )
        }
      </div>
    </div>
  )
}
