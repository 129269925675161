import React from 'react'
import { Option, Select } from '@material-tailwind/react';
import { useState } from 'react';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
import { Icon } from '@iconify/react';
import moment from 'moment';
import Modal from './Modal';


export default function ViewPrivateAgentInvited() {
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (selectedDates) => {
    console.log(selectedDates.length);

    if (selectedDates.length === 1) {
      const formatedStartDate = moment(selectedDates[0]).format("YYYY-MM-DD");
      setStartDate(formatedStartDate);
    }
    if (selectedDates.length === 2) {
      const formatedEndDate = moment(selectedDates[1]).format("YYYY-MM-DD");
      setEndDate(formatedEndDate);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "Y-m-d",
  };

  return (
    <div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center my-[1rem] xl:mx-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1.2rem] font-semibold">
          List
        </h1>
        <Modal />
        <div className="flex gap-[1rem] items-center px-[1rem]">
          <div>
            <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
              <Icon
                icon="mdi:user-add-outline"
                className="text-[1.2rem] sm:text-[1.5rem]"
              />
              <span className="whitespace-nowrap">Invite Users</span>
            </button>
          </div>
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.533975px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search here..."
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            <button onClick={() => setSearchKey("")}
              className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
              <Icon icon="akar-icons:cross" className="text-white" />
            </button>
          </div>
          <div className="max-md:w-full">
            <Flatpickr
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none py-[0.5rem] px-[1rem]"
              style={{
                border: "0.533975px solid #DFDFDF",
                borderRadius: "6px",
              }}
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
          </div>
          <div className="">
            <Select label="Select Country"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent"
            >
              <Option value="India">India</Option>
              <Option value="UAE">UAE</Option>
              <Option value="England">England</Option>
            </Select>
          </div>
        </div>
      </div>
    </div>
  )
}
